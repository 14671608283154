<template>
  <div id="login">
    <div class="top">
      <img src="../assets/images/login/logo.png" alt>
      <p>欢迎登录</p>
    </div>
    <div class="login">
      <div>
        <p :class="['item',{'login-focus':users}]">请输入用户名</p>
        <input
          v-model.trim="userName"
          maxlength="30"
          type="text"
          @focus="userFocus"
          @blur="userBlur"
        >
        <img :class="[{'login-focus-img':users}]" src="../assets/images/login/user.png" alt>
      </div>
      <div>
        <p :class="['item',{'login-focus':words}]">请输登录密码</p>
        <input
          v-model.trim="passWord"
          maxlength="8"
          :type="passwordType"
          @focus="wordFocus"
          @blur="wordBlur"
        >
        <img :class="[{'login-focus-img':words}]" src="../assets/images/login/password.png" alt>
      </div>
      <div v-if="userName == ''|| passWord == ''||!unbind" class="comfirmBtn">登录</div>
      <div v-else-if="unbind" class="comfirmBtn blue" @click="login()">登录</div>
    </div>
    <!-- 冻结弹窗 -->
    <Modal :value="modalShow" @on-cancel="affirmClose" />
    <!-- 选择登录角色 -->
    <BtmModal
      v-if="flag"
      :text="title_text"
      :value="roleModalShow"
      :data="list"
      @on-click="choiceRole"
      @on-cancel="choiceCancel"
    />
  </div>
</template>

<script>
import port from '@/api/port'
import cookies from '@/utils/cookie.js'
export default {
  name: 'Login',
  data() {
    return {
      userName: '',
      passWord: '',
      passwordType: 'password',
      users: false,
      words: false,
      unbind: true,
      modalShow: false, // 冻结提示窗
      title_text: '请选择登录角色',
      roleModalShow: false, // 选择角色弹窗
      list: [],
      flag: false
    }
  },
  methods: {
    kickBack() {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollTop + 1)
        document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1)
      }, 10)
    },
    login() {
      this.kickBack()
      const _this = this
      const data = {
        method: '100000',
        username: this.userName,
        password: this.passWord
      }
      // this.$Indicator.open();
      port.portAddress(data).then(res => {
        // this.$Indicator.close();
        if (res.status == 200) {
          cookies.set('token', res.data.token)
          this.list = res.data.store
          _this.flag = true
          _this.roleModalShow = true
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 选择角色
    choiceRole(val) {
      const _this = this
      this.roleModalShow = false
      const data = {
        method: '100001',
        store_id: val.id,
        token: cookies.get('token')
      }

      port.portAddress(data).then(res => {
        if (res.status == 200) {
          _this.$router.replace({
            path: '/home'
          })
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 取消选择
    choiceCancel() {
      this.roleModalShow = false
    },
    // 冻结
    affirmClose() {
      this.modalShow = false
    },
    userFocus() {
      this.users = true
    },
    userBlur() {
      this.users = this.userName !== ''
    },
    wordFocus() {
      this.words = true
    },
    wordBlur() {
      this.words = this.passWord !== ''
    }
  }
}
</script>

<style lang="less" scoped>
#login {
  background: #fff url("../assets/images/background.png") no-repeat center center;
  background-size: cover;
  height: 100vh;
  position: relative;
  .top {
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 1.4rem;
    img {
      width: 0.96rem;
      margin-bottom: 0.14rem;
    }
    p {
      font-size: 0.41rem;
      color: #004A8F;
    }
  }
  & > .login {
    margin: 0 .27rem;
    & > div {
      height: 1.14rem;
      padding: 0 .27rem;
      position: relative;
      img {
        position: absolute;
        width: 0.27rem;
        height: 0.27rem;
        top: 0.46rem;
        transition: 0.7s;
      }
      input {
        display: block;
        width: 5.34rem;
        padding-bottom: 0.2rem;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 0.01rem solid #CEE3FB;
        font-size: 0.24rem;
        color: #333;
        text-indent: 0.35rem;
        position: absolute;
        top: 0.6rem;
        // z-index: 1;
        border-radius: 0;
      }
      p.item {
        position: absolute;
        font-size: 0.24rem;
        color: #999;
        padding: 0 0.47rem;
        left: 0.09rem;
        top: 0.44rem;
        transition: 0.7s;
        z-index: 0;
      }
      .login-focus {
        top: 0.14rem !important;
        font-size: 0.2rem !important;
      }
      .login-focus-img {
        top: 0.63rem !important;
      }
    }
    .comfirmBtn {
      width: 5.38rem;
      height: 0.68rem;
      line-height: 0.68rem;
      background: #D5E8FC;
      border-radius: 0.1rem;
      text-align: center;
      font-size: 0.27rem;
      color: #fff;
      margin: 1.28rem auto 0;
      box-sizing: border-box;
    }
    .blue {
      background: #2f8af1;
    }
  }
}
</style>
